import get from 'lodash/get';

import { CONTRIBUTOR_ARTICLE_PAGE_SIZE } from '../constants/Contributors';
import { SEARCH_RESULTS_PAGE_SIZE } from '../constants/Search';

import Sanity, { PreviewClient } from '../lib/SanityClient';

import ArticleGroq from '../groq/ArticleGroq';
import ArticleLinkGroq, {
  RecentArticleLinkGroq,
} from '../groq/ArticleLinkGroq';
import AuthorLinkGroq from '../groq/AuthorLinkGroq';
import { ContributorGroq } from '../groq/ContributorGroq';
import ImageGroq from '../groq/ImageGroq';
import PodcastEpisodeGroq from '../groq/PodcastEpisodeGroq';
import PodcastEpisodeLinkGroq from '../groq/PodcastEpisodeLinkGroq';
import PodcastGroq from '../groq/PodcastGroq';
import ArticleContentGroq from '../groq/ArticleContentGroq';
import SectionLinkGroq from '../groq/SectionLinkGroq';
import SectionGroq, { SectionsNavGroq } from '../groq/SectionGroq';
import RecipeGroq from '../groq/RecipeGroq';
import HolidayGroq, {
  HolidayBannerGroq,
  HolidayLinkGroq,
} from '../groq/HolidayGroq';
import PodcastsPageGroq from '../groq/PodcastsPageGroq';

import {
  recipesPageQuery,
  recipesByHolidayQuery,
  allRecipesQuery,
} from '../groq/RecipesPageGroq';
import { recipesByHolidayPageQuery } from '../groq/RecipesByHolidayPageGroq';
import { recipesBySeasonPageQuery } from '../groq/RecipesBySeasonPageGroq';
import LayoutModuleRepeaterGroq from '../groq/LayoutModuleRepeaterGroq';
import sanitizeSectionResponse from '../sanitizers/sanitizeSectionResponse';
import sanitizeArticleResponse from '../sanitizers/sanitizeArticleResponse';
import sanitizeEncyclopediaResponse from '../sanitizers/sanitizeEncyclopediaResponse';
import sanitizeEncyclopediaTermsResponse from '../sanitizers/sanitizeEncyclopediaTermsResponse';
import sanitizePodcastEpisodeResponse from '../sanitizers/sanitizePodcastEpisodeResponse';
import sanitizePodcastEpisodesResponse from '../sanitizers/sanitizePodcastEpisodesResponse';
import sanitizeAuthorsResponse from '../sanitizers/sanitizeAuthorsResponse';
import sanitizePodcastResponse from '../sanitizers/sanitizePodcastResponse';
import sanitizeAuthorResponse from '../sanitizers/sanitizeAuthorResponse';
import sanitizeGenericPageResponse from '../sanitizers/sanitizeGenericPageResponse';
import sanitizeHolidayResponse from '../sanitizers/sanitizeHolidayResponse';
import sanitizeColumnResponse from '../sanitizers/sanitizeColumnResponse';
import sanitizeArticleLinks from '../sanitizers/sanitizeArticleLinks';
import sanitizeRecipeResponse from '../sanitizers/sanitizeRecipeResponse';
import { sanitizeRecipesPageResponse } from '../sanitizers/sanitizeRecipesPageResponse';
import sanitizeHolidayBannerResponse from '../sanitizers/sanitizeHolidayBannerResponse';
import sanitizeColumnistResponse from '../sanitizers/sanitizeColumnistResponse';
import sanitizeFeatureArticlePageResponse from '../sanitizers/sanitizeFeatureArticlePageResponse';
import dateRoundedToMinute from '../utils/dateRoundedToMinute';
import {
  getParamsFromPreviousPage,
  mergePaginationResults,
} from '../utils/paginate';
import { hasLatestStoriesFeatureFlag } from 'utils/next';

import {
  Article,
  ArticleBody,
  ArticleLink,
  Author,
  Collection,
  AuthorLink,
  Encyclopedia,
  EncyclopediaTerm,
  FrontPage,
  GenericPage,
  GridItem,
  Holiday,
  Podcast,
  PodcastEpisode,
  PodcastEpisodeLink,
  RecipeArticle,
  RecipeArticleLink,
  RecipesPageResponse,
  RecipesPageData,
  Section,
  SectionLink,
  TagLink,
  TeamMember,
  HolidayWithRecipes,
  Season,
  RecipesBySeasonPageData,
  RecipesByHolidayPageData,
  BaseData,
  Slugs,
  CollectionsPage,
  PodcastsPage,
  HolidayBanner,
  Column,
  ColumnistsPage,
  FeatureArticlePage,
  SectionMenuLink,
  GlobalSettings,
  Redirect,
  ArticleLinkType,
  Donation,
} from '../sharedTypes';

import {
  PodcastEpisodeStub,
  ArticleStub,
  Paginated,
  Navigation,
} from '../types';
import subDays from 'date-fns/subDays';
import dateRoundedToDay from '../utils/dateRoundedToDat';
import { createEmptyPagination } from '../utils/usePaginatedRequest';
import { CollectionGroq } from '../groq/CollectionGroq';
import sanitizeCollectionResponse from '../sanitizers/sanitizeCollectionResponse';
import sanitizeCollectionsResponse from '../sanitizers/sanitizeCollectionsResponse';
import { CollectionsPageGroq } from '../groq/CollectionsPageGroq';
import sanitizeCollectionsPageResponse from '../sanitizers/sanitizeCollectionsPageResponse';
import { migrateHeroImages } from '../utils/migration';
import { ColumnGroq } from '../groq/ColumnGroq';
import sanitizePodcastsPageResponse from '../sanitizers/sanitizePodcastsPageResponse';
import sanitizeFrontPageResponse from '../sanitizers/sanitizeFrontPageResponse';
import { ColumnistsPageGroq } from '../groq/ColumnistsPageGroq';
import sanitizeColumnistsPageResponse from '../sanitizers/sanitizeColumnistsPageResponse';
import FeatureArticlePageGroq from '../groq/FeatureArticleGroq';
import { definitely } from 'utils/definitely';
import sanitizeRedirectsResponse from 'sanitizers/sanitizeRedirectsResponse';
import DonationGroq from 'groq/DonationGroq';
import sanitizeDonationPageResponse from 'sanitizers/sanitizeDonationPageResponse';
import NavigationGroq from '../groq/NavigationGroq';

type PodcastParams = { podcastSlug: string; podcastEpisodeSlug: string };

const LEGACY_BASE_URL = 'https://www.tabletmag.com';

const previewHandlerGroq = (isPreview: boolean) =>
  `| order(_updatedAt ${isPreview ? 'desc' : 'asc'})`;

const ApiClient = {
  async fetchBase(): Promise<BaseData> {
    const fetchGlobalSettings: Promise<GlobalSettings> =
      this.fetchGlobalSettings();
    const fetchSections: Promise<Section[]> = this.fetchSections();
    const fetchLatestArticles: Promise<ArticleLink[]> =
      hasLatestStoriesFeatureFlag
        ? this.fetchLatestArticles()
        : Promise.resolve(null);
    const fetchRecentArticlesBySection: Promise<ArticleLink[][]> =
      this.fetchRecentArticlesBySection();
    const fetchRedirects: Promise<Redirect[]> = this.fetchRedirects();
    const fetchNavigation: Promise<Navigation> = this.fetchNavigation();

    const [
      navigation,
      globalSettings,
      allSections,
      latestArticles,
      recentArticlesBySection,
      redirects,
    ] = await Promise.all([
      fetchNavigation,
      fetchGlobalSettings,
      fetchSections,
      fetchLatestArticles,
      fetchRecentArticlesBySection,
      fetchRedirects,
    ]);

    const orderedMenuSections = definitely(
      globalSettings!.sectionPageOrder.map(({ _ref }) =>
        allSections!.find((page) => page._id === _ref)
      )
    )
      .filter((section) => !section.hideInSideNav)
      .map<SectionMenuLink>((section) => ({
        _id: section._id,
        _type: section._type,
        title: section.title,
        slug: section.slug,
        images: section.images,
      }));

    return {
      globalSettings: globalSettings,
      latestArticles: latestArticles,
      recentArticlesBySection: recentArticlesBySection,
      orderedMenuSections,
      totalSections: orderedMenuSections.length,
      redirects,
      navigation,
    };
  },
  async fetchSections() {
    // console.time('fetchSections');

    const sections = await Sanity.fetch(
      `*[_type == 'section'] ${SectionsNavGroq}`
    );

    // console.timeEnd('fetchSections');
    return sections;
  },
  async fetchRedirects() {
    const redirects = await Sanity.fetch(`*[_type == 'redirect']`);

    return sanitizeRedirectsResponse(redirects);
  },
  async fetchHolidays() {
    // console.time('fetchHolidays');

    const holidays = await Sanity.fetch(
      `*[_type == 'holiday'] | order(startDate asc) ${HolidayLinkGroq}`
    );

    // console.timeEnd('fetchHolidays');
    return holidays;
  },
  async fetchLatestArticles() {
    // console.time('fetchLatestArticles');

    const now = dateRoundedToDay(new Date());
    const dateTenDaysAgo = subDays(now, 10);
    const formattedDateNow = now.toISOString();
    const formattedDateTenDaysAgo = dateTenDaysAgo.toISOString();

    const latestArticles = await Sanity.fetch(
      `*[_type == 'article' 
        && _createdAt > '${formattedDateTenDaysAgo}' 
        && _createdAt < '${formattedDateNow}'
      ] ${ArticleLinkGroq}`
    );

    // console.timeEnd('fetchLatestArticles');
    return latestArticles;
  },

  // Used for testing purposes columns
  async fetchRecent4Articles() {
    const now = dateRoundedToDay(new Date());
    const dateTenDaysAgo = subDays(now, 10);
    const formattedDateNow = now.toISOString();
    const formattedDateTenDaysAgo = dateTenDaysAgo.toISOString();

    const articles = await Sanity.fetch(
      `*[_type == 'article' 
        && _createdAt > '${formattedDateTenDaysAgo}' 
        && _createdAt < '${formattedDateNow}'
      ] [0..2] ${ArticleLinkGroq}`
    );

    return sanitizeArticleLinks(articles);
  },

  /** Columns */
  async fetchColumn(columnSlug: string) {
    const column = await Sanity.fetch<Collection>(
      `*[_type == 'column' && slug == $columnSlug][0] 
            ${ColumnGroq}
        `,
      { columnSlug }
    );

    if (!column) {
      console.warn(`Could not fetch collection with slug "${ColumnGroq}"`);
      return null;
    }

    return sanitizeColumnResponse(column);
  },

  /*Columns Carousel*/
  async fetchArticlesByColumn(columnSlug: string) {
    const res = await Sanity.fetch<{ articles: ArticleLink[] }>(
      `*[_type == "column" && slug == $columnSlug] {
        "articles": *[
          _type == "article" && 
          column._ref == ^._id
        ] ${ArticleLinkGroq} }[0]`,
      { columnSlug }
    );

    if (!res || res?.articles?.length == 0) {
      console.warn(`There are no articles under column: "${columnSlug}"`);
      return [];
    }
    return sanitizeArticleLinks(res.articles.filter((article) => article.id));
  },

  //Fetch the page description for Columnist Page
  async fetchColumnistsPage(): Promise<ColumnistsPage> {
    const response = await Sanity.fetch(
      `*[_type == 'columnistsPage'] | order(_createdAt desc) [0] ${ColumnistsPageGroq}`
    );
    return sanitizeColumnistsPageResponse(response);
  },

  // Fetch the associated column & most recent article by column refs array
  async fetchAllColumnistsByColumnRefs(ids: string[]) {
    const res = await Sanity.fetch<
      Array<{
        _id: string;
        article: ArticleLink | null;
        column: Column | null;
      }>
    >(
      `*[_type == "column" && _id in $ids] {
      _id,
      title,
      slug,
      description,
      "image": image${ImageGroq},
      "article":  *[_type == "article" && ^._id == column._ref]  | order(releaseDate desc, _createdAt desc) [0] 
      ${ArticleLinkGroq} 
    }`,
      { ids }
    );

    if (!res) {
      console.warn(`There are no articles under columns: "${ids.join(', ')}"`);
      return [];
    }

    // Reorder the results to match the order of the ids array
    const orderedResults = ids
      .map((id) => res.find((column) => column._id === id))
      .filter(Boolean);

    return orderedResults.map(sanitizeColumnistResponse);
  },

  // Fetch all columns and their most recent articles
  async fetchAllColumns() {
    const res = await Sanity.fetch<
      Array<{ article: ArticleLink | null; column: Column | null }>
    >(
      `*[_type == "column"] {
      _id,
      title,
      slug,
      description,
      releaseDate,
      "image": image${ImageGroq},
      "article":  *[_type == "article" && ^._id == column._ref]  | order(releaseDate desc, _createdAt desc) [0] 
      ${ArticleLinkGroq} 
    }`
    );

    if (!res || res.length === 0) {
      console.warn('There are no columns or articles.');
      return null;
    }

    return res.map(sanitizeColumnistResponse);
  },

  async fetchArticlesToMigrate() {
    const date = new Date(2015, 1, 1).toISOString();
    const currentDate = new Date().toISOString();

    let articlesToMigrate = await Sanity.fetch(
      `*[_type == 'article'
          && _createdAt < '${date}'
          && heroImage.asset._type != 'reference'
          && body[0]._type == 'insetImage'
        ] [0..1] ${ArticleGroq}`,
      { currentDate }
    );

    while (articlesToMigrate.length > 0) {
      migrateHeroImages(articlesToMigrate);

      // throttle for a second
      await new Promise((resolve) => setTimeout(resolve, 5000));

      /** comment out next line to run until migration is complete */
      articlesToMigrate = [];

      /** uncomment to run migration in loop */
      // articlesToMigrate = await Sanity.fetch(
      //   `*[_type == 'article'
      //       && _createdAt < '${date}'
      //       && heroImage.asset._type != 'reference'
      //       && body[0]._type == 'insetImage'
      //     ] [0..1] ${ArticleGroq}`, { currentDate }
      // );
    }
    return;
  },

  async fetchRecentArticlesBySection() {
    const sections = await Sanity.fetch(`*[_type == 'section'] {
        _id,
        slug
      }`);

    const query = ({ _id: ref }: { _id: string }) =>
      Sanity.fetch(`*[_type in ['article', 'featureArticlePage'] && section._ref == '${ref}']|order(_createdAt desc) [0...6]
        ${RecentArticleLinkGroq}
      `);

    const slugOrder = [
      'news',
      'arts-letters',
      'belief',
      'israel-middle-east',
      'food',
      'holidays',
      'community',
      'history',
      'science',
      'sports',
    ];

    const filteredSections = sections.filter((section: { slug: string }) =>
      slugOrder.includes(section.slug)
    );

    const response = await Promise.all(
      slugOrder.map((slug) =>
        query(
          filteredSections.find(
            (section: { slug: string }) => section.slug === slug
          )
        )
      )
    );

    return response;
  },
  /* Front Page */
  async fetchFrontPage(previewId?: string): Promise<FrontPage> {
    const isPreview = Boolean(previewId);
    const Client = isPreview ? PreviewClient : Sanity;
    const query = isPreview
      ? `_type == 'frontPage' && _id == '${previewId}'`
      : `_type == 'frontPage' && defined(releaseDate) && releaseDate < '${dateRoundedToMinute().toISOString()}'`;
    const frontPage = await Client.fetch(
      `*[${query}] | order(releaseDate desc, _createdAt desc) [0] { ${LayoutModuleRepeaterGroq}, ${HolidayGroq}, 
      "featuredImage": featuredImage${ImageGroq},
      featuredLink,
      showTagline,
      taglineText,
    }`
    );
    return sanitizeFrontPageResponse(frontPage);
  },

  async fetchGlobalSettings() {
    const data = await Sanity.fetch(
      `*[_type == 'globalSettings'][0] { 
        teamMembers[]->{ 
          "avatarImage": avatarImage${ImageGroq}, 
          firstName, 
          lastName, 
          jobTitle, 
          slug, 
          bio 
        }, 
        membershipLink,
        tabletAppButtonText,
        tabletAppLink,
        newJewishEncyclopediaLink,
        sectionScrollCTA,
        donationPopupIsActive,
        donationPopupTitle,
        donationPopupDescription,
        donationPopupLink,
        donationPopupAmounts,
        emailSignUpPopupIsActive,
        emailSignUpPopupTitle,
        emailSignUpPopupDescription,
        emailSignUpPopupNewsletter -> {
          newsletterListSubscribeLink,
          successResponse
        },
        "sectionPageOrder": sectionPages[]{
          _key,
          _ref
        }
      }`
    );
    return data;
  },

  /* Tags */
  async fetchTag(slug: string): Promise<TagLink | null> {
    const tag = await Sanity.fetch<TagLink>(
      `
      *[_type == "tag" && slug == $slug]{
        ...
      }[0]
    `,
      { slug }
    );
    if (!tag) {
      console.warn(`Could not fetch tag "${slug}"`);

      return null;
    }
    return tag;
  },

  /* Articles */
  async fetchArticle(
    slug: string,
    previewId?: string
  ): Promise<Article | null> {
    const isPreview = Boolean(previewId);
    const Client = isPreview ? PreviewClient : Sanity;

    const query = `*[_type == 'article' && slug == $slug] ${previewHandlerGroq(
      isPreview
    )} [0] ${ArticleGroq}`;

    const currentDate = isPreview ? new Date('2099-01-01') : new Date();
    const article = await Client.fetch<Article>(query, {
      slug,
      currentDate,
    });

    if (!article?.id) {
      const message = previewId
        ? `Preview article "${slug}" was not found`
        : `Article "${slug}" was not found`;

      console.warn(message);
      return null;
    }

    return sanitizeArticleResponse(article);
  },

  async fetchArticleContent(articleSlug: string): Promise<ArticleBody[]> {
    const response = await Sanity.fetch(
      `*[_type == 'article' && slug == $articleSlug][0] { ${ArticleContentGroq(
        'body'
      )}}`,
      { articleSlug }
    );
    return get(response, 'body', []);
  },

  async fetchArticlesByTag(
    tag: string,
    prevData?: Paginated<ArticleLink>
  ): Promise<Paginated<ArticleLink>> {
    const { after, prevTotal } = getParamsFromPreviousPage(prevData);
    const start = after;
    const end = start + CONTRIBUTOR_ARTICLE_PAGE_SIZE;
    const currentDate = new Date();
    const response = await Sanity.fetch<
      TagLink & {
        articles: ArticleLink[];
        total: number;
      }
    >(
      `*[_type == "tag" && slug == $slug][0] {
        ...,
        "articles": *[
          _type == "article" &&
          ^._id in tags[]._ref &&
          (!defined(releaseDate) || releaseDate <= $currentDate)
        ] | order(coalesce(releaseDate, _createdAt) desc) [$start...$end] ${ArticleLinkGroq},
        ${
          prevTotal
            ? ''
            : `"total": count(
          *[
            _type == 'article' &&
            ^._id in tags[]._ref &&
            (!defined(releaseDate) || releaseDate <= $currentDate)
          ])`
        }
      }`,
      /* ☝️  Don't query for the total if we already know what it is - it adds about 2 seconds to the response time */
      { start, end, slug: tag, currentDate }
    );
    if (!response?.articles) {
      console.warn(`Could not fetch articles tagged with "${tag}"`);
      return createEmptyPagination();
    }

    const { articles, total } = response;
    return mergePaginationResults(articles, total, prevData);
  },

  async fetchArticlesBySection(
    slug: string,
    prevData?: Paginated<ArticleLinkType>
  ): Promise<Paginated<ArticleLinkType>> {
    const { after, prevTotal } = getParamsFromPreviousPage(prevData);
    const start = after;
    const end = start + CONTRIBUTOR_ARTICLE_PAGE_SIZE;
    const currentDate = new Date();
    // console.time('fetchArticlesBySection');
    const section = await Sanity.fetch<{ _id: string }>(
      `*[_type == "section" && slug == $slug]{ _id, slug }[0]`,
      { slug }
    );
    if (!section) {
      throw new Error(`Could not fetch section "${slug}"`);
    }
    const response = await Sanity.fetch<{
      articles: ArticleLinkType[];
      total: number;
    }>(
      `{
        "articles": *[
          _type in ["article", "featureArticlePage"] &&
          section._ref == $sectionId &&
          (!defined(releaseDate) || releaseDate <= $currentDate)
        ] | order(coalesce(releaseDate, _createdAt) desc) [${start}...${end}] ${ArticleLinkGroq},
        ${
          prevTotal
            ? ''
            : `"total": count(
              *[
                _type == 'article' &&
                section._ref == $sectionId &&
                (!defined(releaseDate) || releaseDate <= $currentDate)
              ])`
        }
      }`,
      /* ☝️  Don't query for the total if we already know what it is - it adds about 2 seconds to the response time */

      { start, end, slug, currentDate, sectionId: section._id }
    );

    // console.timeEnd('fetchArticlesBySection');
    if (!response?.articles) {
      console.warn(`Could not fetch articles for section "${slug}"`);
      return createEmptyPagination();
    }
    const { total, articles } = response;
    return mergePaginationResults(articles, total, prevData);
  },

  async fetchArticlesByContributor(
    contributorSlug: string,
    prevData?: Paginated<ArticleLinkType>
  ): Promise<Paginated<ArticleLinkType>> {
    const { after, prevTotal } = getParamsFromPreviousPage(prevData);
    const start = after;
    const end = start + CONTRIBUTOR_ARTICLE_PAGE_SIZE;
    const currentDate = new Date();
    const response = await Sanity.fetch<{
      articles: ArticleLinkType[];
      total: number;
    }>(
      `*[_type == 'author' && slug == $contributorSlug || legacySlug == $contributorSlug][0] {
        "articles": *[
          _type in ["article", "featureArticlePage"] &&
          ^._id in authors[]._ref &&
          (!defined(releaseDate) || releaseDate <= $currentDate)
        ] | order(coalesce(releaseDate, _createdAt) desc) [${start}...${end}] ${ArticleLinkGroq},
        ${
          prevTotal
            ? ''
            : `"total": count(*[_type == 'article' && ^._id in authors[]._ref])`
        }
      }`,
      /* ☝️  Don't query for the total if we already know what it is - it adds about 2 seconds to the response time */
      { start, end, contributorSlug, currentDate }
    );
    if (!response?.articles) {
      console.warn(
        `Could not fetch contributor articles for "${contributorSlug}"`
      );

      return createEmptyPagination();
    }
    const { articles, total } = response;
    return mergePaginationResults(articles, total, prevData);
    // const total = prevTotal || response.total;
    // return { ...response, total };
  },

  async search(
    searchTerm: string,
    prevData?: Paginated<GridItem>
  ): Promise<Paginated<GridItem>> {
    const { after, prevTotal } = getParamsFromPreviousPage(prevData);
    const start = after;
    const end =
      after === 0
        ? /* If this is the first search, fetch one less item - the first
           * row on the search results page has 3 items, not 4
           */
          SEARCH_RESULTS_PAGE_SIZE - 1
        : start + SEARCH_RESULTS_PAGE_SIZE;

    const currentDate = new Date();
    const response = await Sanity.fetch<{
      results: GridItem[];
      total: number;
    }>(
      `{
        "results": *[
          (_type == 'article' || _type == 'podcastEpisode')
          && [title] match [$searchTerm]
          && (!defined(releaseDate) || releaseDate <= $currentDate)
        ] | order(coalesce(releaseDate, _createdAt) desc) [$start...$end] {
          'id': _id,
          'heroImage': heroImage${ImageGroq},
          title,
          'image': image${ImageGroq},
          section->${SectionLinkGroq},
          credits,
          dek,
          slug,
          authors[]->{ firstName, lastName, slug, legacySlug },
          'releaseDate': coalesce(releaseDate, _createdAt),
          'createdAt': _createdAt,
          podcast->{
            _id,
            slug,
            title,
            credits
          },
          _type,
          'type': _type
        },
        ${
          prevTotal
            ? ''
            : `"total": count(
              *[
                (_type == 'article' || _type == 'podcastEpisode')
                && [title] match [$searchTerm]
                && (!defined(releaseDate) || releaseDate <= $currentDate)
              ])`
        }
      }`,
      /* ☝️  Don't query for the total if we already know what it is - it adds about 2 seconds to the response time */
      { start, end, searchTerm, currentDate }
    );
    if (!response?.results) {
      console.warn(`Could not fetch search results for term "${searchTerm}"`);

      return createEmptyPagination();
    }
    const { results, total } = response;
    return mergePaginationResults(results, total, prevData);
  },

  /* Contributors */
  async fetchAllContributors(): Promise<AuthorLink[]> {
    const response = await Sanity.fetch(
      `*[_type == 'author'] ${AuthorLinkGroq}`
    );
    return sanitizeAuthorsResponse(response);
  },

  async fetchContributor(contributorSlug: string) {
    const contributor = await Sanity.fetch<Author>(
      `*[_type == 'author' && slug == $contributorSlug || legacySlug == $contributorSlug][0] {
          ${ContributorGroq}
      }`,
      { contributorSlug }
    );

    if (!contributor?._id) {
      console.warn(
        `Could not fetch contributor with slug "${contributorSlug}"`
      );
      return null;
    }
    return sanitizeAuthorResponse(contributor);
  },

  /* Collections */
  async fetchCollections(): Promise<Collection[]> {
    const response = await Sanity.fetch(
      `*[_type == 'collection'] | order(_createdAt desc) ${CollectionGroq}`
    );
    return sanitizeCollectionsResponse(response);
  },

  async fetchCollectionsPage(): Promise<CollectionsPage> {
    const response = await Sanity.fetch(
      `*[_type == 'collectionsPage'] | order(_createdAt desc) [0] ${CollectionsPageGroq}`
    );
    return sanitizeCollectionsPageResponse(response);
  },

  async fetchCollection(collectionSlug: string) {
    const collection = await Sanity.fetch<Collection>(
      `*[_type == 'collection' && slug == $collectionSlug][0] 
            ${CollectionGroq}
        `,
      { collectionSlug }
    );

    if (!collection) {
      console.warn(`Could not fetch collection with slug "${collectionSlug}"`);
      return null;
    }

    return sanitizeCollectionResponse(collection);
  },

  async fetchArticlesByCollection(collectionSlug: string) {
    const res = await Sanity.fetch<{ articles: ArticleLink[] }>(
      `*[_type == "collection" && slug == $collectionSlug] {
        "articles": *[
          _type == "article" && 
          collection._ref == ^._id
        ] ${ArticleLinkGroq} }[0]`,
      { collectionSlug }
    );

    if (!res || res?.articles?.length == 0) {
      console.warn(
        `There are no articles under collection: "${collectionSlug}"`
      );
      return [];
    }
    return sanitizeArticleLinks(res.articles);
  },

  async fetchCollectionById(collectionId: string) {
    const collection = await Sanity.fetch<Collection>(
      `*[_type == 'collection' && _id == $collectionId][0] 
            ${CollectionGroq}
        `,
      { collectionId }
    );

    return sanitizeCollectionResponse(collection);
  },

  async fetchArticlesByCollectionRef(collectionRef: string) {
    const res = await Sanity.fetch<{ articles: ArticleLink[] }>(
      `*[_type == "collection"] {
        "articles": *[
          _type == "article" && 
          collection._ref == $collectionRef
        ] ${ArticleLinkGroq} }[0]`,
      { collectionRef }
    );

    if (!res || res?.articles?.length == 0) {
      console.warn(
        `There are no articles under collection: "${collectionRef}"`
      );
      return [];
    }
    return sanitizeArticleLinks(res.articles);
  },

  /* Encylopedia */
  async fetchEncyclopedia(): Promise<Encyclopedia> {
    const response = await Sanity.fetch(
      `*[_type == 'encyclopedia'][0] { title, description, "stickerImage": stickerImage${ImageGroq}, stickerLink }`
    );

    return sanitizeEncyclopediaResponse(response);
  },

  async fetchEncyclopediaTerms(): Promise<EncyclopediaTerm[]> {
    const response = await Sanity.fetch(
      `*[_type == 'encyclopediaTerm'] { term, pronunciation, typeOfWord, definition }`
    );

    return sanitizeEncyclopediaTermsResponse(response);
  },

  /* Pages */
  async fetchGenericPages(): Promise<Slugs> {
    const response = await Sanity.fetch<Slugs>(
      `*[_type == 'genericPage'] {
        slug,
      }`
    );

    return response || [];
  },
  async fetchGenericPage(slug: string): Promise<GenericPage> {
    const response = await Sanity.fetch(
      `*[_type == 'genericPage' && slug == '${slug}'][0] {
        title,
        slug,
        "blocks": blocks[]{ "id": _key, "type": _type, ... },
        "seo": {
          "title": seoTitle,
          "description": seoDescription,
          "image": seoImage${ImageGroq}
        }
      }`
    );

    return sanitizeGenericPageResponse(response);
  },

  /* Donation Page*/
  async fetchDonationPage(): Promise<Donation> {
    const response = await Sanity.fetch(
      `*[_type == 'donationPage'][0] ${DonationGroq}`
    );
    return sanitizeDonationPageResponse(response);
  },

  /* Feature Article Pages */
  async fetchFeatureArticlePages(): Promise<Slugs> {
    const response = await Sanity.fetch<Slugs>(
      `*[_type == 'featureArticlePage'] {
          slug,
        }`
    );

    return response || [];
  },
  async fetchFeatureArticlePage(
    slug: string,
    previewId?: string
  ): Promise<FeatureArticlePage | null> {
    const isPreview = Boolean(previewId);
    const Client = isPreview ? PreviewClient : Sanity;

    const query = `*[_type == 'featureArticlePage' && slug == $slug] ${previewHandlerGroq(
      isPreview
    )} [0] ${FeatureArticlePageGroq}`;

    const currentDate = isPreview ? new Date('2099-01-01') : new Date();
    const article = await Client.fetch<FeatureArticlePage>(query, {
      slug,
      currentDate,
    });

    const sanitizedArticle = sanitizeFeatureArticlePageResponse(article);
    return sanitizedArticle;
  },

  /* Holidays */
  async fetchHoliday(slug: string, preview: string): Promise<Holiday | null> {
    const isPreview = Boolean(preview);
    const Client = isPreview ? PreviewClient : Sanity;

    const query = `*[_type == 'holiday' && slug == $slug] ${previewHandlerGroq(
      isPreview
    )} [0] {
          ${HolidayGroq}
        }`;
    const params = { slug };
    const response = await Client.fetch<Holiday>(query, params);
    if (!response?.id) {
      console.warn(`Could not fetch data for holiday "${slug}"`);

      return null;
    }

    return sanitizeHolidayResponse(response);
  },

  /* Holiday Masthead */
  async fetchHolidayBannerById(holidayId: string) {
    const response = await Sanity.fetch<HolidayBanner>(
      `*[_type == 'holiday' && _id == $holidayId][0] 
          ${HolidayBannerGroq}
      `,
      { holidayId }
    );

    return sanitizeHolidayBannerResponse(response);
  },

  /* Podcasts */
  async fetchPodcastsPage(): Promise<PodcastsPage> {
    const response = await Sanity.fetch(
      `*[_type == 'podcastsPage'] | order(_createdAt desc) [0] ${PodcastsPageGroq}`
    );

    return sanitizePodcastsPageResponse(response);
  },

  async fetchPodcasts(): Promise<Podcast[]> {
    const query = `*[_type == 'globalSettings'][0] { 
        podcasts[]-> ${PodcastGroq},
      }`;

    const result = await Sanity.fetch<{ podcasts: Podcast[] }>(query);
    if (!result || !result.podcasts) {
      throw new Error('Could not load podcasts from globalSettings');
    }
    return result.podcasts;
  },

  async fetchPodcastsSlugs(): Promise<Slugs> {
    const query = `*[_type == "podcast"] {
      slug
    }`;

    const podcasts = await Sanity.fetch<Slugs>(query);

    return podcasts || [];
  },
  async fetchPodcast(slug: string, previewId: string): Promise<Podcast | null> {
    const isPreview = Boolean(previewId);

    const Client = isPreview ? PreviewClient : Sanity;

    const query = `*[_type == "podcast" && slug == $slug] ${previewHandlerGroq(
      isPreview
    )} [0] ${PodcastGroq}`;

    const podcast = await Client.fetch<Podcast>(query, { slug });

    if (!podcast?.id) {
      const message = isPreview
        ? `Preview podcast "${slug}" was not found`
        : `Podcast "${slug}" was not found`;

      console.warn(message);
      return null;
    }

    return sanitizePodcastResponse(podcast);
  },

  async fetchPodcastEpisode(
    params: PodcastParams,
    previewId: string
  ): Promise<PodcastEpisode | null> {
    const isPreview = Boolean(previewId);
    const { podcastSlug, podcastEpisodeSlug } = params;

    const Client = isPreview ? PreviewClient : Sanity;

    const query = `*[_type == "podcastEpisode" && slug == $podcastEpisodeSlug] ${previewHandlerGroq(
      isPreview
    )} {
      "podcastEpisode": ${PodcastEpisodeGroq}
    } [0]`;

    const response = await Client.fetch<{ podcastEpisode: PodcastEpisode }>(
      query,
      { podcastSlug, podcastEpisodeSlug }
    );
    const podcastEpisode = response?.podcastEpisode;
    if (!podcastEpisode?.id) {
      const message = isPreview
        ? `Preview for podcast episode "${podcastSlug} / ${podcastEpisodeSlug}" was not found`
        : `Podcast episode "${podcastSlug} / ${podcastEpisodeSlug}" was not found`;
      console.warn(message);
      return null;
    }

    return sanitizePodcastEpisodeResponse(podcastEpisode);
  },

  async fetchEpisodesByPodcast(
    podcastSlug: string,
    prevData?: Paginated<PodcastEpisodeLink>
  ): Promise<Paginated<PodcastEpisodeLink>> {
    const { after, prevTotal } = getParamsFromPreviousPage(prevData);
    const start = after;
    const end = start + CONTRIBUTOR_ARTICLE_PAGE_SIZE;
    const currentDate = new Date();

    // using bigDate so that with coalesce, dateTime is never null.
    // This is because Sanity doesn't allow null values in dateTime
    // And in GROQ RHS of || is still being evaluated even if LHS is true
    const bigDate = new Date(86000000000000);

    const response = await Sanity.fetch<{
      episodes: PodcastEpisodeLink[];
      total: number;
    }>(
      `*[_type == "podcast" && slug == $podcastSlug] {
        "episodes": *[
          _type == "podcastEpisode" &&
          (
            !defined(releaseDate) || dateTime(coalesce(releaseDate, $bigDate)) <= dateTime($currentDate)
          ) &&
          podcast._ref == ^._id 
        ] | order(coalesce(releaseDate, _createdAt) desc) [$start...$end] ${PodcastEpisodeLinkGroq},
        ${
          prevTotal
            ? ''
            : `"total": count(*[
                  _type == "podcastEpisode" && podcast._ref == ^._id
                  && (
                    !defined(releaseDate) || dateTime(coalesce(releaseDate, $bigDate)) <= dateTime($currentDate)
                  )
                ])`
        }
      }[0]`,
      /* ☝️  Don't query for the total if we already know what it is - it adds about 2 seconds to the response time */
      { start, end, podcastSlug, currentDate, bigDate }
    );

    if (!response?.episodes) {
      console.warn(`Could not fetch episodes for podcast "${podcastSlug}"`);
      return createEmptyPagination();
    }
    const { episodes, total } = response;
    return mergePaginationResults(episodes, total, prevData);
  },

  async fetchPodcastEpisodeById(
    id: string,
    uuid: string
  ): Promise<PodcastEpisode | null> {
    const response = await PreviewClient.fetch(
      `*[_type == 'podcastEpisode' && _id == '${id}' && _id != '${uuid}'][0] ${PodcastEpisodeGroq}`
    );

    return sanitizePodcastEpisodeResponse(response);
  },

  async fetchPodcastEpisodeByRef(id: string): Promise<PodcastEpisodeLink> {
    const response = await PreviewClient.fetch(
      `*[_type == 'podcastEpisode' && _id == '${id}'][0] ${PodcastEpisodeGroq}`
    );

    return sanitizePodcastEpisodeResponse(response);
  },

  async fetchPodcastEpisodesByPodcastId(
    podcastId: string,
    limit: number
  ): Promise<PodcastEpisodeLink[] | null> {
    const response = await Sanity.fetch(
      `*[
        _type == 'podcastEpisode' &&
        podcast._ref == '${podcastId}'
      ] | order(_createdAt desc) ${PodcastEpisodeGroq} [0...${limit}]`
    );

    return sanitizePodcastEpisodesResponse(response);
  },

  /* Recipes */

  async fetchRecipe(
    slug: string,
    previewId?: string
  ): Promise<RecipeArticle | null> {
    const isPreview = Boolean(previewId);
    const Client = isPreview ? PreviewClient : Sanity;

    const query = `*[_type == 'recipeArticle' && slug == $slug] ${previewHandlerGroq(
      isPreview
    )} [0] ${RecipeGroq}`;
    const params = { slug };
    const recipe = await Client.fetch<RecipeArticle>(query, params);

    if (!recipe?.id) {
      const message = isPreview
        ? `Preview recipe "${slug}" was not found`
        : `Recipe "${slug}" was not found`;

      console.warn(message);

      return null;
    }
    return sanitizeRecipeResponse(recipe);
  },

  async fetchRecipes(
    _param = null,
    prevData?: Paginated<RecipeArticleLink>
  ): Promise<Paginated<RecipeArticleLink>> {
    const { after, prevTotal } = getParamsFromPreviousPage(prevData);
    const start = after;
    const end = start + CONTRIBUTOR_ARTICLE_PAGE_SIZE;
    const currentDate = new Date();
    const response = await Sanity.fetch<{
      recipes: RecipeArticleLink[];
      total: number;
    }>(
      `{
          "recipes": ${allRecipesQuery}, 

          ${
            prevTotal
              ? ''
              : `
            "total": count(
              *[
                _type == "recipeArticle" &&
                (!defined(releaseDate) || releaseDate <= $currentDate)
              ]), 
            `
          }

      }`,
      { start, end, currentDate }
    );
    if (!response?.recipes) {
      console.warn(`Could not fetch all recipes (after: ${after})`);
      return createEmptyPagination();
    }

    const { recipes, total } = response;
    return mergePaginationResults(recipes, total, prevData);
  },

  async fetchRecipesPageData(
    preview?: string
  ): Promise<RecipesPageResponse | null> {
    const Client = !!preview ? PreviewClient : Sanity;

    const currentDate = preview ? new Date('2099-01-01') : new Date();
    const recipesPageParams = {
      id: preview ? preview : 'recipesPage',
      currentDate,
    };

    const [
      recipesPage,
      allRecipes,
      //TODO: Re-enable seasonal once tablet wants it up
      // seasonal,
      byHoliday,
      foodSection,
    ] = await Promise.all([
      Client.fetch<RecipesPageData>(recipesPageQuery, recipesPageParams),
      ApiClient.fetchRecipes(),
      // Client.fetch<RecipesBySeason>(recipesBySeasonQuery, { currentDate }),
      // NEXT_TODO: Paginate this
      Client.fetch<HolidayWithRecipes[]>(recipesByHolidayQuery, {
        currentDate,
      }),
      ApiClient.fetchSection('food'),
    ]);

    if (
      !recipesPage ||
      !allRecipes ||
      // !seasonal ||
      !byHoliday ||
      !foodSection
    ) {
      console.warn(`Could not load recipes page data`);
      return null;
    }

    const responses: RecipesPageResponse = {
      recipesPage,
      allRecipes,
      // seasonal,
      byHoliday,
      foodSection,
    };

    return sanitizeRecipesPageResponse(responses);
  },

  /** DEPRECATED */
  // TODO: paginate
  async fetchRecipesByHoliday(
    slug: string,
    preview?: string
  ): Promise<RecipesByHolidayPageData | null> {
    const Client = !!preview ? PreviewClient : Sanity;
    const currentDate = new Date();
    const response = await Client.fetch<RecipesByHolidayPageData>(
      recipesByHolidayPageQuery,
      { slug, currentDate }
    );
    return response;
  },

  /** DEPRECATED */
  // TODO: paginate
  async fetchRecipesBySeason(
    season: Season,
    preview?: string
  ): Promise<RecipesBySeasonPageData | null> {
    const Client = !!preview ? PreviewClient : Sanity;
    const response = await Client.fetch<RecipesBySeasonPageData>(
      recipesBySeasonPageQuery(season)
    );
    return response;
  },

  /* Sections */

  async fetchSection(
    identifier: string,
    previewId?: string
  ): Promise<Section | null> {
    const isPreview = Boolean(previewId);
    const Client = isPreview ? PreviewClient : Sanity;
    const query = `*[_type == 'section' && slug == $slug] ${previewHandlerGroq(
      isPreview
    )} [0] ${SectionGroq}`;
    const response = await Client.fetch<Section>(query, {
      slug: identifier,
    });
    if (!response?._id) {
      console.warn(`Could not load data for section "${identifier}"`);
      return null;
    }
    return sanitizeSectionResponse(response);
  },

  async fetchAllSections(): Promise<SectionLink[]> {
    const response = await Sanity.fetch<SectionLink[]>(
      `*[_type == 'section']${SectionLinkGroq}`
    );
    if (!response) {
      console.warn(`Could not load data for all sections`);
      return [];
    }
    return response.map(sanitizeSectionResponse);
  },

  /* Team Members */

  async fetchTeamMember(slug: string): Promise<TeamMember | null> {
    const teamMember = await Sanity.fetch<TeamMember>(
      `*[_type == 'teamMember' && slug == $slug] {
          _type,
          "avatarImage": avatarImage${ImageGroq}, 
          firstName, 
          lastName, 
          jobTitle, 
          slug, 
          bio 
      }[0]`,
      { slug }
    );
    if (!teamMember) {
      console.warn(`Could not find team member with slug "${slug}"`);
      return null;
    }
    return teamMember;
  },

  /* Misc by legacy params */

  async fetchArticleOrPodcastByLegacyPathname(
    pathname: string
  ): Promise<ArticleStub | PodcastEpisodeStub | null> {
    const response = await Sanity.fetch<ArticleStub | PodcastEpisodeStub>(
      `*[
          (_type == 'article' || _type == 'podcastEpisode')
          && legacyArticlePathname == $legacyArticlePathname
        ]{
          _type,
          "podcast": podcast->{slug},
          section->${SectionLinkGroq},
          slug,
        }[0]`,
      { legacyArticlePathname: LEGACY_BASE_URL.concat(pathname) }
    );
    return response;
  },

  async fetchNavigation() {
    const navigation = await Sanity.fetch(NavigationGroq);
    return navigation;
  },
};

export default ApiClient;
