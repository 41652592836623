import Head from 'next/head';
import getPortableTextAsString from 'utils/getPortableTextAsString';

import {
  Article as IArticle,
  Holiday as IHoliday,
  Podcast as IPodcast,
  PodcastEpisode as IPodcastEpisode,
  TeamMember as ITeamMember,
  Column as IColumn,
  Author as IAuthor,
  FeatureArticlePage as IFeatureArticle,
  AuthorLink,
  PortableText,
  SeoSettings,
  Collection as ICollection,
  Donation as IDonation,
} from 'sharedTypes';

import generateAuthorUrl from 'utils/generateAuthorUrl';
import generatePodcastEpisodeUrl from 'utils/generatePodcastEpisodeUrl';
import environmentIsProd from 'utils/environmentIsProd';
import { useRouter } from 'next/router';
import { convertSanityURLToImageCDNUrl } from 'utils/imageBuilder';

const DEFAULT_TITLE = 'Tablet Magazine';
const DEFAULT_TITLE_ENDING = ' - Tablet Magazine';
const DEFAULT_DESCRIPTION =
  'Tablet features news, essays, podcasts, and opinion, covering arts, pop culture, technology, holidays, sports, and more.';
const DEFAULT_IMAGE = '/assets/images/tablet-share-card.jpg';

interface Props {
  title?: string;
  description?: string;
  image?: string;
  canonical?: string;
  isPreview?: boolean;
  is404?: boolean;
  forceNoCache?: boolean;
  jsonLd?: object;
  children?: React.ReactNode;
  noRobots?: boolean;
}

const HOST = environmentIsProd()
  ? `https://www.tabletmag.com`
  : `https://staging.tabletmag.com`;

const ABSOLUTE_URL_PATTERN = new RegExp('^(?:[a-z]+:)?//', 'i');
const urlIsAbsolute = (url: string): boolean => {
  return ABSOLUTE_URL_PATTERN.test(url);
};

const stringStartsWith = (needle: string, haystack: string): boolean => {
  return haystack?.substring(0, needle.length) === needle;
};

const Meta: React.FC<Props> = ({
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION,
  image = DEFAULT_IMAGE,
  canonical,
  jsonLd,
  children,
  noRobots = false,
}) => {
  if (!image) {
    image = DEFAULT_IMAGE;
  }

  const originalImage = image;

  if (!urlIsAbsolute(image)) {
    image = `${HOST}${stringStartsWith('/', image) ? image : '/' + image}`;
  }

  const { asPath } = useRouter();

  const innerCanonical = canonical || asPath;

  return (
    <Head>
      <title>
        {title
          ? title.includes('Tablet Magazine')
            ? title
            : `${title}${DEFAULT_TITLE_ENDING}`
          : `${DEFAULT_TITLE}`}
      </title>
      <meta name="description" content={description} />
      <link rel="canonical" href={innerCanonical} />
      {noRobots && <meta name="robots" content="noindex nofollow" />}
      <meta key="fb:app_id" property="fb:app_id" content="172479889593604" />
      <meta key="fb:admins-2600851" property="fb:admins" content="2600851" />
      <meta key="fb:admins-1310198" property="fb:admins" content="1310198" />
      <meta
        key="fb:admins-1457454176"
        property="fb:admins"
        content="1457454176"
      />
      <meta
        key="fb:admins-624278173"
        property="fb:admins"
        content="624278173"
      />
      <meta
        key="fb:admins-502317431"
        property="fb:admins"
        content="502317431"
      />
      <meta
        key="fb:admins-548462312"
        property="fb:admins"
        content="548462312"
      />
      <meta
        key="fb:admins-100001276307949"
        property="fb:admins"
        content="100001276307949"
      />
      <meta
        key="fb:admins-1113801086"
        property="fb:admins"
        content="1113801086"
      />
      <meta
        key="fb:admins-100002183003511"
        property="fb:admins"
        content="100002183003511"
      />
      <meta key="fb:admins-13802855" property="fb:admins" content="13802855" />
      <meta
        key="fb:admins-800222784"
        property="fb:admins"
        content="800222784"
      />
      <meta
        key="fb:admins-218101514"
        property="fb:admins"
        content="218101514"
      />

      <meta key="og:title" property="og:title" content={title} />
      <meta
        key="og:description"
        property="og:description"
        content={description}
      />
      <meta
        key="og:image"
        property="og:image"
        content={
          urlIsAbsolute(originalImage)
            ? convertSanityURLToImageCDNUrl(`${image}?w=1200`)
            : image
        }
      />
      <meta key="og:url" property="og:url" content={innerCanonical} />
      <meta
        key="og:site_name"
        property="og:site_name"
        content="Tablet Magazine"
      />
      <meta key="og:locale" property="og:locale" content="en_US" />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta
        name="twitter:image"
        content={
          urlIsAbsolute(originalImage)
            ? convertSanityURLToImageCDNUrl(`${image}?w=1200`)
            : image
        }
      />
      <meta name="twitter:site" content="@tabletmag" />
      <meta name="twitter:creator" content="@tabletmag" />
      <meta name="twitter:card" content="summary_large_image" />

      {jsonLd && (
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      )}

      {children ? children : null}
    </Head>
  );
};

const Article: React.FC<{
  article: IArticle;
  isPreview: boolean;
  defaultImage?: string;
}> = ({ article, isPreview, defaultImage = DEFAULT_IMAGE }) => {
  const { asPath } = useRouter();

  const seoTitle = article.seo.title ? article.seo.title : article.title;

  const fallbackDescription = article.meta.brief
    ? article.meta.brief
    : article.meta.excerpt
    ? article.meta.excerpt
    : DEFAULT_DESCRIPTION;
  const seoDescription = article.seo.description
    ? article.seo.description
    : fallbackDescription;

  const fallbackImage = article.hero.heroImage.src
    ? article.hero.heroImage.src
    : defaultImage;
  const seoImage = article.seo.image.src
    ? article.seo.image.src
    : fallbackImage;

  return (
    <Meta
      title={seoTitle}
      description={seoDescription}
      image={seoImage}
      canonical={article.slug}
      isPreview={isPreview}
      jsonLd={{
        '@id': `${HOST}${asPath}`,
        '@context': 'https://schema.org',
        '@type': 'Article',
        name: seoTitle,
        headline: seoTitle,
        articleBody: getPortableTextAsString(article.content.body as object[]),
        author: article.meta.authors.map((author: AuthorLink) => ({
          '@context': 'https://schema.org',
          '@type': 'Person',
          name: `${author.firstName} ${author.lastName}`,
          familyName: author.lastName,
          givenName: author.firstName,
        })),
        articleSection: article.meta.section.title,
        abstract: seoDescription,
        image: seoImage,
        datePublished: article.meta.date,
        dateModified: article.meta.updatedAt,
        publisher: {
          '@type': 'Organization',
          name: 'Tablet Magazine',
        },
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': `${HOST}${article.slug}`,
        },
        alternativeHeadline: article.hero.dek,
      }}
    >
      <link rel="amphtml" href={`/amp${article.slug}`} />
      <meta key="og:type" property="og:type" content="article" />
      <meta
        key="article:tag"
        property="article:tag"
        content={article.meta.tags.map((tag) => tag.title).join(', ')}
      />
      <meta
        key="article:section"
        property="article:section"
        content={article.meta.section.title}
      />
      <meta
        key="article:published_time"
        property="article:published_time"
        content={article.meta.date}
      />
      <meta
        key="article:publisher"
        property="article:publisher"
        content="https://www.facebook.com/TabletMag/"
      />
    </Meta>
  );
};

const Contributor: React.FC<{ contributor: IAuthor }> = ({ contributor }) => {
  const description = contributor.bio
    ? getPortableTextAsString(contributor.bio)
    : undefined;
  const { asPath } = useRouter();
  return (
    <Meta
      title={`${contributor.firstName} ${contributor.lastName}`}
      description={description}
      canonical={generateAuthorUrl(contributor)}
      jsonLd={{
        '@id': `${HOST}${asPath}`,
        '@context': 'https://schema.org',
        '@type': 'Person',
        givenName: contributor.firstName,
        familyName: contributor.lastName,
        name: `${contributor.firstName} ${contributor.lastName}`,
        url: `${HOST}${asPath}`,
        description,
        jobTitle: 'Contributor',
      }}
    />
  );
};

const FeatureArticle: React.FC<{
  featureArticle: IFeatureArticle;
  isPreview: boolean;
  defaultImage?: string;
}> = ({ featureArticle, isPreview, defaultImage = DEFAULT_IMAGE }) => {
  const { asPath } = useRouter();

  const seoTitle = featureArticle.seo.title
    ? featureArticle.seo.title
    : featureArticle.title;

  const seoDescription = featureArticle.seo.description
    ? featureArticle.seo.description
    : DEFAULT_DESCRIPTION;

  const fallbackImage = featureArticle.heroImage.src
    ? featureArticle.heroImage.src
    : defaultImage;
  const seoImage = featureArticle.seo.image.src
    ? featureArticle.seo.image.src
    : fallbackImage;

  return (
    <Meta
      title={seoTitle}
      description={seoDescription}
      image={seoImage}
      canonical={`/feature/${featureArticle.slug}`}
      isPreview={isPreview}
      jsonLd={{
        '@id': `${HOST}${asPath}`,
        '@context': 'https://schema.org',
        '@type': 'FeatureArticle',
        name: seoTitle,
        headline: seoTitle,
        articleBody: getPortableTextAsString(
          featureArticle.content.body as object[]
        ),
        abstract: seoDescription,
        image: seoImage,
        publisher: {
          '@type': 'Organization',
          name: 'Tablet Magazine',
        },
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': `${HOST}${featureArticle.slug}`,
        },
      }}
    >
      <meta key="og:type" property="og:type" content="featureArticle" />
      <meta
        key="featureArticle:publisher"
        property="featureArticle:publisher"
        content="https://www.facebook.com/TabletMag/"
      />
    </Meta>
  );
};

const Holiday: React.FC<{ holiday: IHoliday }> = ({ holiday }) => {
  const { asPath } = useRouter();

  const seoTitle = holiday.seo.title ? holiday.seo.title : holiday.title;

  const fallbackDescription = holiday.briefDescription
    ? holiday.briefDescription
    : DEFAULT_DESCRIPTION;
  const seoDescription = holiday.seo.description
    ? holiday.seo.description
    : fallbackDescription;

  const seoImage = holiday.seo.image.src
    ? holiday.seo.image.src
    : DEFAULT_IMAGE;

  return (
    <Meta
      title={seoTitle}
      description={seoDescription}
      image={seoImage}
      jsonLd={{
        '@id': `${HOST}${asPath}`,
        '@context': 'https://schema.org',
        '@type': 'Event',
        name: seoTitle,
        url: `${HOST}${asPath}`,
        image: seoImage,
        description: holiday.seo.description
          ? holiday.seo.description
          : getPortableTextAsString(holiday.detailedDescription),
      }}
    />
  );
};

const Page: React.FC<{
  title?: string;
  description?: string | PortableText;
  canonical?: string;
  image?: string;
  seo?: SeoSettings;
  noRobots?: boolean;
}> = ({
  title = DEFAULT_TITLE,
  description,
  canonical,
  image = DEFAULT_IMAGE,
  seo,
  noRobots,
}) => {
  const { asPath } = useRouter();
  const innerCanonical = canonical || asPath;

  const seoTitle = seo && seo.title ? seo.title : title;

  const fallbackDescription = description
    ? getPortableTextAsString(description)
    : DEFAULT_DESCRIPTION;
  const seoDescription =
    seo && seo.description ? seo.description : fallbackDescription;

  const seoImage = seo && seo.image.src ? seo.image.src : image;

  return (
    <Meta
      noRobots={noRobots}
      title={seoTitle}
      canonical={innerCanonical}
      description={seoDescription}
      image={seoImage}
      jsonLd={{
        '@id': `${HOST}${asPath}`,
        '@context': 'https://schema.org',
        '@type': 'WebPage',
        name: seoTitle,
        image: seoImage,
      }}
    />
  );
};

const Podcast: React.FC<{ podcast: IPodcast }> = ({ podcast }) => {
  const { asPath } = useRouter();

  const seoTitle = podcast.seo.title ? podcast.seo.title : podcast.title;

  const fallbackDescription = podcast.description
    ? podcast.description
    : DEFAULT_DESCRIPTION;
  const seoDescription = podcast.seo.description
    ? podcast.seo.description
    : fallbackDescription;

  const fallbackImage = podcast.image.src ? podcast.image.src : DEFAULT_IMAGE;
  const seoImage = podcast.seo.image.src
    ? podcast.seo.image.src
    : fallbackImage;

  return (
    <Meta
      title={seoTitle}
      description={seoDescription}
      image={seoImage}
      jsonLd={{
        '@id': `${HOST}${asPath}`,
        '@context': 'https://schema.org',
        '@type': 'CreativeWorkSeries',
        name: seoTitle,
        url: `${HOST}${asPath}`,
        description: seoDescription,
        image: seoImage,
        headline: podcast.title,
        alternativeHeadline: podcast.dek,
        author:
          podcast.credits &&
          podcast.credits.map((author: string) => ({
            '@context': 'https://schema.org',
            '@type': 'Person',
            name: author,
            jobTitle: `Host of ${podcast.title}`,
          })),
      }}
    />
  );
};

const Collection: React.FC<{ collection: ICollection }> = ({ collection }) => {
  const { asPath } = useRouter();

  const seoDescription = collection.description
    ? collection.description
    : DEFAULT_DESCRIPTION;

  const seoImage =
    collection && collection.image?.src ? collection.image.src : DEFAULT_IMAGE;

  return (
    <Meta
      title={collection.title}
      description={seoDescription}
      image={seoImage}
      jsonLd={{
        '@id': `${HOST}${asPath}`,
        '@context': 'https://schema.org',
        '@type': 'CreativeWorkSeries',
        name: collection.title,
        url: `${HOST}${asPath}`,
        description: seoDescription,
        image: seoImage,
        headline: collection.title,
      }}
    />
  );
};

const Column: React.FC<{ column: IColumn }> = ({ column }) => {
  const { asPath } = useRouter();

  return (
    <Meta
      title={column.title}
      description={column.description}
      image={column.image.src}
      jsonLd={{
        '@id': `${HOST}${asPath}`,
        '@context': 'https://schema.org',
        '@type': 'CreativeWorkSeries',
        name: column.title,
        url: `${HOST}${asPath}`,
        description: column.description,
        image: column.image?.src,
        headline: column.title,
      }}
    />
  );
};

const PodcastEpisode: React.FC<{
  podcastEpisode: IPodcastEpisode;
  isPreview: boolean;
}> = ({ podcastEpisode, isPreview }) => {
  const { asPath } = useRouter();
  if (!podcastEpisode.podcast) return <Meta />;

  const seoTitle = podcastEpisode.seo.title
    ? podcastEpisode.seo.title
    : podcastEpisode.title;

  const fallbackDescription = podcastEpisode.description
    ? podcastEpisode.description
    : DEFAULT_DESCRIPTION;
  const seoDescription = podcastEpisode.seo.description
    ? podcastEpisode.seo.description
    : fallbackDescription;

  const fallbackImage = podcastEpisode.image.src
    ? podcastEpisode.image.src
    : DEFAULT_IMAGE;
  const seoImage = podcastEpisode.seo.image.src
    ? podcastEpisode.seo.image.src
    : fallbackImage;

  return (
    <Meta
      title={seoTitle}
      canonical={generatePodcastEpisodeUrl(
        podcastEpisode.podcast.slug,
        podcastEpisode.slug
      )}
      description={seoDescription}
      image={seoImage}
      isPreview={isPreview}
      jsonLd={{
        '@id': `${HOST}${asPath}`,
        '@context': 'https://schema.org',
        '@type': 'Episode',
        name: seoTitle,
        url: `${HOST}${asPath}`,
        description: seoDescription,
        headline: podcastEpisode.title,
        alternativeHeadline: podcastEpisode.dek,
        image: seoImage,
        partOfSeries: {
          '@context': 'https://schema.org',
          '@type': 'CreativeWorkSeries',
          name: podcastEpisode.podcast.title,
          headline: podcastEpisode.podcast.title,
          alternativeHeadline: podcastEpisode.podcast.dek,
          image: podcastEpisode.podcast.image.src,
        },
      }}
    />
  );
};

const TeamMember: React.FC<{ teamMember: ITeamMember }> = ({ teamMember }) => {
  const { asPath } = useRouter();
  return (
    <Meta
      title={`${teamMember.firstName} ${teamMember.lastName}`}
      description={getPortableTextAsString(teamMember.bio)}
      image={teamMember.avatarImage.src}
      jsonLd={{
        '@id': `${HOST}${asPath}`,
        '@context': 'https://schema.org',
        '@type': 'Person',
        givenName: `${teamMember.firstName} ${teamMember.lastName}`,
        description: getPortableTextAsString(teamMember.bio),
        image: teamMember.avatarImage.src,
        name: `${teamMember.firstName} ${teamMember.lastName}`,
        url: `${HOST}${asPath}`,
        jobTitle: teamMember.jobTitle,
      }}
    />
  );
};

const Donation: React.FC<IDonation> = ({
  headerImage,
  seo,
  headline,
  bodyText,
}) => {
  const { asPath } = useRouter();

  const fallbackHeadline = headline || DEFAULT_TITLE;
  const fallbackDescription =
    getPortableTextAsString(bodyText) || DEFAULT_DESCRIPTION;
  const fallbackImage = headerImage?.src || DEFAULT_IMAGE;
  const seoTitle = seo.title ? seo.title : fallbackHeadline;
  const seoDescription = seo.description
    ? seo.description
    : fallbackDescription;
  const seoImage = seo.image.src ? seo.image.src : fallbackImage;

  return (
    <Meta
      title={seoTitle}
      canonical="/donate"
      description={seoDescription}
      image={seoImage}
      jsonLd={{
        '@id': `${HOST}${asPath}`,
        '@context': 'https://schema.org',
        '@type': 'WebPage',
        name: seoTitle,
        image: seoImage,
      }}
    />
  );
};

export {
  Article,
  Contributor,
  FeatureArticle,
  Holiday,
  Page,
  Podcast,
  PodcastEpisode,
  TeamMember,
  Collection,
  Column,
  Donation,
};

export default Meta;
