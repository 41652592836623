import ImageGroq from './ImageGroq';

const NavigationGroq = `
  *[_type == "navigation" && _id == "navigation"][0] {
    mainMenu[] {
      _key,
      text,
      url,
      "image": image${ImageGroq}
    },
    secondaryMenu[] {
      _key,
      text,
      url
    }
  }
`;

export default NavigationGroq;
